<template>
  <div>
    <div class="alert alert-danger" v-show="error">{{ error }}</div>
    
    <div class="text-center mb-3">
      <div class="fb-login-button" data-width="" data-size="large" data-button-type="login_with" data-scope="email"
           data-layout="default" data-auto-logout-link="true" data-use-continue-as="true"></div>
    </div>
    
    <div class="text-center mb-3">
      <div id="g_id_signin"></div>
    </div>
    
    <div class="text-center mb-3">
      <div id="appleid-signin" class="signin-button" data-color="black" data-border="true" data-type="sign-in"></div>
    </div>
  </div>
</template>

<script>

import config from '@/config'

export default {
  name: "OAuthSignIn",
  props: {
    minutes: {
      type: Number,
      required: false,
    },
    redirect: {
      type: String,
      required: false,
      default: ""
    },
  },
  emits: ['success'],
  data() {
    return {
      error: '',
    }
  },
  created() {
    if (!import.meta.env.SSR) {
      const _this = this
      
      // Facebook Connect.
      let fbRoot = document.createElement('div')
      fbRoot.id = 'fb-root'
      document.body.appendChild(fbRoot)
      let element = document.createElement('script')
      element.async = true
      element.defer = true
      element.crossorigin = 'anonymous'
      element.src = 'https://connect.facebook.net/en_US/sdk.js'
      document.head.appendChild(element)
      
      // let redirectUrl = '/advisors/online'
      // if (this.$route.query.redirect_url) {
      //     redirectUrl = this.$route.query.redirect_url
      //         + '?action=' + encodeURIComponent(this.$route.query.action)
      //         + '&param=' + encodeURIComponent(this.$route.query.param)
      
      //     if (this.minutes && this.minutes > 0) {
      //         redirectUrl += '&minutes=' + this.minutes
      //     }
      // }
      // redirectUrl = window.location.origin + redirectUrl
      
      window.fbAsyncInit = function () {
        FB.init({
          appId: config.facebook.appId,
          cookie: false,
          xfbml: true,
          version: 'v15.0',
        })
        
        FB.AppEvents.logPageView()
        
        FB.Event.subscribe('auth.authResponseChange', function (response) {
          if (response?.status === 'connected') {
            _this.$auth.facebookSignIn({
              data: {
                token: response.authResponse.accessToken,
              },
              redirect: _this.redirect,
              error: function (res) {
                _this.error = res.response.message || 'The credentials you supplied were not correct.'
              },
              success: () => {
                _this.$emit('success');
              }
            })
          } else if (response?.status === 'not_authorized') {
            _this.error = 'The credentials you supplied were not correct.'
          }
        });
      }
      
      // Google Sign In.
      element = document.createElement('script')
      element.async = true
      element.defer = true
      element.src = 'https://accounts.google.com/gsi/client'
      element.addEventListener("load", function () {
        google.accounts.id.initialize({
          client_id: config.google.clientId,
          // :data-login_uri='$router.url'
          callback: (response) => {
            if (response?.credential) {
              _this.$auth.googleSignIn({
                data: {
                  token: response.credential,
                },
                redirect: _this.redirect,
                error: function (res) {
                  _this.error = res.response.message || 'The credentials you supplied were not correct.'
                },
                success: () => {
                  _this.$emit('success');
                }
              })
            }
          }
        })
        const containerWidth = document.getElementById('g_id_signin').offsetWidth
        google.accounts.id.renderButton(
          document.getElementById('g_id_signin'),
          {
            theme: 'outline',
            size: 'large',
            width: containerWidth + 2,
            height: 47,
            logo_alignment: 'center',
          }
        )
        google.accounts.id.prompt() // also display the One Tap dialog
      }, false)
      document.head.appendChild(element)
      
      // Apple ID.
      element = document.createElement('script')
      element.src = "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js"
      element.addEventListener("load", function () {
        // Apple does not support wildcard URLs in allowed redirect URL settings for auth service, using static one.
        // let appleRedirectUrl = window.location.origin + (['/login', '/register'].includes(_this.$route.path) ? _this.$route.path : '/advisors/online')
        let appleRedirectUrl = window.location.origin + '/advisors/online'
        
        window.AppleID.auth.init({
          clientId: config.apple.clientId,
          scope: 'name email',
          redirectURI: appleRedirectUrl,
          state: 'origin:web' + Math.random(),
          usePopup: true,
        })
        // Fixing apple icon on most devices.
        setTimeout(function () {
          let signInText = document.querySelector('#appleid-signin text')
          signInText.innerHTML = signInText.innerHTML.replace('\uf8ff', '&nbsp; &nbsp; &nbsp; ')
          let icon = document.createElementNS('http://www.w3.org/2000/svg', 'g')
          icon.innerHTML = '<svg x="-10" y="-21" width="32px" height="32px" version="1.1" viewBox="10 10 36 36" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"><path d="m28.223 22.385c0.83203 0 1.875-0.57978 2.4961-1.3528 0.5625-0.70057 0.97266-1.679 0.97266-2.6573 0-0.13287-0.01172-0.26574-0.03516-0.37444-0.92578 0.03624-2.0391 0.64018-2.707 1.4495-0.52734 0.61602-1.0078 1.5823-1.0078 2.5728 0 0.14495 0.02344 0.28989 0.03516 0.33821 0.05859 0.01208 0.15234 0.02416 0.24609 0.02416zm-2.9297 14.615c1.1367 0 1.6406-0.78512 3.0586-0.78512 1.4414 0 1.7578 0.76097 3.0234 0.76097 1.2422 0 2.0742-1.1837 2.8594-2.3433 0.87891-1.3287 1.2422-2.6332 1.2656-2.6936-0.08203-0.02416-2.4609-1.0267-2.4609-3.8411 0-2.4399 1.875-3.5391 1.9805-3.6236-1.2422-1.836-3.1289-1.8843-3.6445-1.8843-1.3945 0-2.5312 0.86968-3.2461 0.86968-0.77344 0-1.793-0.82136-3-0.82136-2.2969 0-4.6289 1.9568-4.6289 5.6529 0 2.295 0.86719 4.7228 1.9336 6.2931 0.91406 1.3287 1.7109 2.4158 2.8594 2.4158z" fill="#fff"/></svg>'
          let signInSvg = document.querySelector('#appleid-signin svg svg text')
          if (signInSvg.parentNode) {
            signInSvg.parentElement.insertBefore(icon, signInSvg)
          } else {
            console.error("apple icon insert - target element not found");
          }
        }, 100)
        
        document.addEventListener('AppleIDSignInOnSuccess', (event) => {
          // Handle successful response.
          _this.$auth.appleSignIn({
            data: {
              token: event.detail?.authorization?.id_token,
              name: [(event.detail?.user?.name?.firstName || ''), (event.detail?.user?.name?.lastName || '')].filter(Boolean).join(' ') || null,
            },
            redirect: appleRedirectUrl,
            error: function (res) {
              _this.error = res.response.message || 'The credentials you supplied were not correct.'
            },
            success: function (res) {
              _this.$emit('success')
            },
          })
        })
        
      }, false)
      document.head.appendChild(element)
      
    }
  },
}
</script>

<style lang="scss">
.fb-login-button {
  width: 100%;
  height: 47px;
}

.fb-login-button > span,
.fb-login-button > span > iframe {
  width: 100% !important;
  height: 47px !important;
  background-color: #1877f2;
  border-radius: 0.5rem;
  max-width: 100%;
}

.fb-login-button > span > iframe {
  padding-top: 4px;
  padding-left: min(6rem, calc(6rem - 3 * calc(6rem - 18vw)));
}

#g_id_signin {
  margin-left: 0em;
  height: 47px;
  
  div[aria-labelledby="button-label"] {
    width: 100% !important;
    height: 47px !important;
    max-width: 100% !important;
  }
  
  iframe {
    height: 47px !important;
    //width: 100% !important;
    //margin: 0 !important;
  }
}

#appleid-signin {
  width: 100%;
  height: 47px;
}

#appleid-signin > div {
  max-width: 100% !important;
  
  div:first-child {
    padding: 1.2%;
  }
}
</style>
